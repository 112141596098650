<template>
  <LSection>
    <template #body>
      <StatusMessage operation="PUT_ASSET"></StatusMessage>
      <div
        class="w-full flex flex-col-reverse k1:flex-row justify-between shadow-md k1:gap-8 k1:shadow-none"
      >
        <div
          v-if="accord?.id"
          class="p-6 min-h-96 min-w-[50vw] flex flex-col gap-2 bg-white shadow-none k1:shadow-md"
        >
          <div class="text-3xl">
            <div class="font-semibold">
              <TextInput
                :tabIndex="1"
                placeholder="Accord Title"
                :value="accord.title"
                :isEditing="canEdit && editing"
                @change="(value) => onChange('title', value)"
                @input="(value) => onChange('title', value)"
              ></TextInput>
            </div>
            <div class="border-b-2"></div>
            <!--            <div class="flex gap-12">-->
            <!--              <div class="text-xs mt-1 flex gap-2">-->
            <!--                Start date:-->
            <!--                <TextInput-->
            <!--                    :tabIndex="4"-->
            <!--                    :isEditing="canEdit && editing"-->
            <!--                    :value="accord.start"-->
            <!--                    type="date"-->
            <!--                    name="start"-->
            <!--                    placeholder="Start-Date"-->
            <!--                    @change="value => accord.start = value"-->
            <!--                ></TextInput>-->
            <!--              </div>-->
            <!--              <div class="text-xs mt-1 flex gap-2">-->
            <!--                <div v-if="accord.end || canEdit && editing">End date:</div>-->
            <!--                <TextInput-->
            <!--                    :tabIndex="5"-->
            <!--                    :isEditing="canEdit && editing"-->
            <!--                    :value="accord.end"-->
            <!--                    type="date"-->
            <!--                    name="end"-->
            <!--                    placeholder="End-Date"-->
            <!--                    @change="value => accord.end = value"-->
            <!--                ></TextInput>-->
            <!--              </div>-->
            <!--            </div>-->
            <div class="text-xs mt-1 flex gap-2">
              Location:
              <TextInput
                :tabIndex="6"
                :isEditing="canEdit && editing"
                :value="accord.countries"
                name="countries"
                placeholder="Countries / Areas"
                @change="(value) => (accord.countries = value)"
              ></TextInput>
            </div>
            <div class="text-lg pt-4">
              <div v-if="editing">
                [visible on the card only]
                <span
                  v-if="descriptionCharactersLeft !== descriptionMaxCharacters"
                >
                  ({{ descriptionCharactersLeft }})
                </span>
                <TextArea
                  :tabIndex="7"
                  :minLines="12"
                  :isEditing="canEdit && editing"
                  :value="accord.description"
                  name="description"
                  placeholder="Description"
                  @change="(value) => (accord.description = value)"
                  @input="(value) => (accord.description = value)"
                ></TextArea>
              </div>
              <!--              <div v-else>-->
              <!--                {{accord.description}}-->
              <!--              </div>-->
            </div>

            <div class="text-sm pt-4 relative z-40">
              <h1 class="text-3xl font-semibold mb-6">Action plan</h1>
              <Markdown
                :modelValue="accord.actionPlan"
                :tabIndex="10"
                :isEditable="canEdit && editing"
                name="actionPlan"
                placeholder="Action Plan"
                @change="(value) => (accord.actionPlan = value)"
              ></Markdown>
            </div>

            <div v-if="editing" class="w-full">
              <h2 class="text-lg mb-4">Accord Principles</h2>
            </div>
            <div v-if="editing" class="flex gap-x-12 gap-y-2 flex-wrap mb-4">
              <div
                v-for="key in Object.keys(principles)"
                :key="key"
                class="flex gap-4 w-1/2"
                @click="() => onPrincipleChange(key)"
              >
                <input
                  type="checkbox"
                  :name="'principle' + key"
                  :checked="accord.principles.includes(key)"
                />
                <h2 class="text-sm">{{ principles[key].name }}</h2>
              </div>
            </div>

            <div class="flex flex-row gap-2 align-center flex-wrap mb-4">
              <template
                v-for="key in Object.keys(availablePrinciples)"
                :key="key"
                class="flex"
              >
                <div
                  v-if="accord.principles && accord.principles.includes(key)"
                  class="flex flex-row group/principle"
                >
                  <div
                    class="h-3 w-5 rounded-full self-center"
                    :class="{
                      ['bg-' + availablePrinciples[key].colorClass]: true,
                    }"
                  ></div>
                  <p class="pl-1 text-xs self-center">
                    {{ availablePrinciples[key].name }}
                  </p>
                  <div class="hidden group-hover/principle:block popUp">
                    <p>{{ availablePrinciples[key].info }}</p>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div v-if="canEdit" class="buttons w-full flex justify-between">
            <button
              v-if="canEdit && !editing"
              class="button main"
              @click="() => (editing = canEdit)"
            >
              Edit Accord
            </button>
            <button v-if="editing" class="button alt" @click="onCancel">
              Cancel
            </button>
            <button
              v-if="canEdit && editing"
              class="button main w-60"
              @click="onSave"
            >
              Save
            </button>
          </div>
        </div>

        <div
          class="top-32 p-6 min-h-96 h-fit k1:sticky min-w-80 k1:w-full flex flex-col gap-2 bg-white shadow-none k1:shadow-md"
        >
          <div
            class="flex flex-col gap-2 cursor-pointer"
            @click="
              $router.push({
                name: 'organisationAbout',
                params: { organisationId: organisationId },
              })
            "
          >
            <CompanyLogo :organisationImg="organisation.imageUrl" />
            <div class="flex justify-between">
              <div class="text-sm">{{ organisation.name }}</div>
              <img
                src="/eqrcmedals_coin_level1.svg"
                class="award w-10 h-10"
                alt=""
              />
            </div>
            <div
              class="text-xs my-3"
              style="
                display: -webkit-box;
                -webkit-line-clamp: 6;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
              "
            >
              {{ organisation.description }}
            </div>
          </div>
          <div class="flex-col flex gap-2 text-xs justify-center">
            <div class="flex gap-x-2 items-center">
              <svg
                width="11"
                height="15"
                viewBox="0 0 11 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.875 0H1.375C0.61875 0 0 0.675 0 1.5V13.5C0 14.325 0.61875 15 1.375 15H9.625C10.3813 15 11 14.325 11 13.5V4.5L6.875 0ZM5.5 6C6.25625 6 6.875 6.675 6.875 7.5C6.875 8.325 6.25625 9 5.5 9C4.74375 9 4.125 8.325 4.125 7.5C4.125 6.675 4.74375 6 5.5 6ZM8.25 12H2.75V11.5725C2.75 10.965 3.08 10.425 3.58875 10.185C4.17313 9.9075 4.81938 9.75 5.5 9.75C6.18062 9.75 6.82687 9.9075 7.41125 10.185C7.92 10.425 8.25 10.965 8.25 11.5725V12Z"
                  fill="#0F1043"
                />
              </svg>
              <div class="w-24">Contact Person:</div>

              <div class="text-blue-500 ml-auto">
                <TextInput
                  :tabIndex="1"
                  placeholder="Name of Accord Contact Person"
                  :value="accord.contactPersonName"
                  :isEditing="canEdit && editing"
                  @change="(value) => onChange('contactPersonName', value)"
                  @input="(value) => onChange('contactPersonName', value)"
                ></TextInput>
              </div>
            </div>
            <div class="flex gap-x-1">
              <div class="pt-0.5">
                <svg
                  class="self-center"
                  width="14"
                  height="11"
                  viewBox="0 0 14 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.6 0H1.4C0.63 0 0.00699999 0.61875 0.00699999 1.375L0 9.625C0 10.3813 0.63 11 1.4 11H12.6C13.37 11 14 10.3813 14 9.625V1.375C14 0.61875 13.37 0 12.6 0ZM12.6 2.75L7 6.1875L1.4 2.75V1.375L7 4.8125L12.6 1.375V2.75Z"
                    fill="#0F1043"
                  />
                </svg>
              </div>

              <div class="w-36">Contact Person email:</div>
              <div class="text-blue-500 ml-auto">
                <TextInput
                  :tabIndex="1"
                  placeholder="Email of Accord Contact Person"
                  :value="accord.contactPersonEmail"
                  :isEditing="canEdit && editing"
                  @change="(value) => onChange('contactPersonEmail', value)"
                  @input="(value) => onChange('contactPersonEmail', value)"
                ></TextInput>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </LSection>
</template>

<script>
import LSection from "@/components/layout/LSection.vue";
import { useDocument } from "@/composables/useDocument";
import availablePrinciples from "@/components/accords/principles";
import principles from "@/components/accords/principles";
import { useUserPermissions } from "@/composables/useUserPermissions";
import { computed, ref, watch } from "vue";
import TextInput from "@/components/forms/TextInput.vue";
import StatusMessage from "@/components/status/StatusMessage.vue";
import config from "../../../config";
import TextArea from "@/components/forms/TextArea.vue";
import Markdown from "@/components/forms/Markdown.vue";
import CompanyLogo from "@/components/accords/CompanyLogo.vue";

const { permissionThresholds } = config;

export default {
  name: "AccordDisplay",
  components: {
    CompanyLogo,
    Markdown,
    TextArea,
    StatusMessage,
    TextInput,
    LSection,
  },
  props: {
    organisationId: {
      type: String,
      required: true,
    },
    assetId: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const { user } = useUserPermissions();
    const { organisation, loadOrganisation } = useDocument(
      "Organisation",
      props.organisationId
    );

    const { accord, loadAccord, updateAccord } = useDocument(
      "Accord",
      props.assetId
    );
    const editing = ref(false);
    const pristineAccord = ref({});
    const canEdit = computed(
      () => accord.value._permissions >= permissionThresholds.maintainAsset
    );

    const descriptionMaxCharacters = 150;
    const descriptionCharactersLeft = computed(
      () => descriptionMaxCharacters - (accord.value?.description?.length ?? 0)
    );

    loadOrganisation();
    loadAccord();

    watch(
      accord,
      (value) => {
        // todo: extract into useDocument w/ abort, startEditing, endEditing
        value.actionPlan = value.actionPlan || "";
        if (value?.id) {
          pristineAccord.value = { ...value };
        }
      },
      { immediate: true }
    );

    const onSave = () => {
      if (accord.value?.description?.length > descriptionMaxCharacters) {
        accord.value.description = accord.value.description.substring(
          0,
          descriptionMaxCharacters
        );
      }
      editing.value = false;
      updateAccord(accord.value);
    };
    const onCancel = () => {
      editing.value = false;
      Object.assign(accord.value, pristineAccord.value);
    };

    return {
      accord,
      availablePrinciples,
      canEdit,
      descriptionCharactersLeft,
      descriptionMaxCharacters,
      editing,
      onCancel,
      onChange: (key, value) => (accord.value[key] = value),
      onPrincipleChange: (key) => {
        const docPrinciples = new Set(accord.value.principles ?? []);
        docPrinciples[accord.value.principles.includes(key) ? "delete" : "add"](
          key
        );
        accord.value.principles = [...docPrinciples];
      },
      onSave,
      organisation,
      principles,
      user,
    };
  },
};
</script>

<style scoped></style>

},
